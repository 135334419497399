import React from 'react';
import './Welcome.css'

function Welcome(props) {
    return (
        <div className='welcome-main' style={{zIndex: "2000"}}>
            <div className='nexus-container'>
                <h3 className='ne'>NE</h3>
                <img className='x' src='./images/x.svg' alt='x'/>
                <h3 className='us'>US</h3>
            </div>
        </div>
    );
}

export default Welcome;