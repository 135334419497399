// src/firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCOivrNeAik_lM6UsIsSZpf5MSSM7QyLyA",
  authDomain: "nexus-tickets.firebaseapp.com",
  projectId: "nexus-tickets",
  storageBucket: "nexus-tickets.appspot.com",
  messagingSenderId: "409615009639",
  appId: "1:409615009639:web:96cb4abe89975bddd351c2",
  measurementId: "G-QTQCTZ425X"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);

export { firestore };