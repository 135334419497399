import './Footer.css'

function Footer() {
    return (
        <footer style={{zIndex: "1000"}} data-animate='slideInUp' className='animate'>
            <div className='footer-container c_lg'>
                <div className='footer-left'>
                    <p>Get in touch</p>
                    <div className='insta-mail'>
                        <a target='_blank' rel='noopener noreferrer' href='https://www.instagram.com/thenexus.eg?igsh=cnV6MzhvZGZ2NXBm&utm_source=qr' className='get-in-touch insta'>
                            <img src='./images/insta.svg' alt='insta'/>
                            <p className='c_lg'>thenexus.eg</p>
                        </a>
                        <a href="mailto:info@thenexusegypt.com?subject=Subject%20Here&body=Body%20text%20here" target="_blank" rel="noopener noreferrer" className='get-in-touch'>
                            <img src='./images/email.svg' alt='email'/>
                            <p className='c_lg'>info@thenexusegypt.com</p>
                        </a>
                    </div>
                </div>
                <div className='footer-right'>
                    <p>Download our app now & book our latest event!</p>
                    <div className='download-app'>
                        <img loading='lazy' src='./images/appstore.png' alt='appstore'/>
                        <img loading='lazy' src='./images/googleplay.png' alt='googleplay'/>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;