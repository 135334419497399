import './Home.css'
import Service from '../components/Service';
import Contact from '../components/Contact'
import { useState, useEffect } from 'react';
import './MainBackground.css'

function Home(props) {
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    const data = [
        "Nexus excels in artist booking by leveraging an extensive global network to secure top-tier talent from around the world. We offer custom curated lineups that are meticulously tailored to match the unique vibe and audience of each event. Our dedicated team handles all aspects of negotiations and contracting, ensuring a smooth and hassle-free booking process. We also provide comprehensive support throughout the engagement, from initial inquiry to post-event follow-up, guaranteeing that both artists and event organizers have a seamless experience.",
        "Our event management services encompass the full spectrum of planning and execution, ensuring every event is a standout success. We start with innovative concept development, crafting unique and memorable themes that resonate with our audiences. Our meticulous planning covers every detail, including budgeting, timelines, and logistics, to ensure nothing is left to chance. On the day of the event, our experienced on-site management team oversees all operations, from setup to breakdown, ensuring everything runs smoothly and any issues are promptly addressed. Our goal is to create unforgettable experiences that exceed expectations.",
        "Nexus specializes in identifying and securing premier venues across Egypt. Our venue coordination services include detailed layout planning and vendor coordination to ensure the venue meets all event requirements. We handle all logistical aspects, including setup and teardown, and ensure compliance with local regulations by obtaining all necessary permits. Our strong relationships with venue owners and managers allow us to offer a wide range of options to suit any event size and style, ensuring the perfect setting for every event.",
        "We provide comprehensive hospitality and logistics support to ensure a smooth and enjoyable experience for international artists. This includes coordinating all travel arrangements, such as flights, ground transportation, and visas, ensuring a hassle-free journey to and from Egypt. We also secure top-tier accommodation options, tailored to the artists' preferences, to ensure their comfort. During their stay, our on-ground support team is available 24/7 to address any needs or issues that may arise, providing a high level of service and care that ensures artists can focus on delivering their best performances."
    ];
    const list = props.activeCards;
    let index;
    if (list[0] === true) {
        index = 0;
    } else if (list[1] === true) {
        index = 1;
    } else if (list[2] === true) {
        index = 2;
    } else if (list[3] === true) {
        index = 3;
    }
    let src;
    let src2;
    let backgroundCls;
    if (props.percent >= -120 && props.percent < 20) {
        src = "./images/people/photo_0x01.png"
        src2 = "./images/backgrounds/ball1.svg"
        backgroundCls = 'main-background-green'
    } else if (props.percent >= 20 && props.percent < 40) {
        src = "./images/people/photo_0x02.png"
        src2 = "./images/backgrounds/ball2.svg"
        backgroundCls = 'main-background-blue'
    } else if (props.percent >= 40 && props.percent < 60) {
        src = "./images/people/photo_0x03.png"
        src2 = "./images/backgrounds/ball1.svg"
        backgroundCls = 'main-background-green'
    } else if (props.percent >= 60 && props.percent < 80) {
        backgroundCls = 'main-background-green'
    } else if (props.percent >= 80 && props.percent < 101) {
        backgroundCls = 'main-background-green'
    }

    return (
        <main>
            <div className={backgroundCls}>
            </div>
            <section id='main' className='main-container'>
                {
                    props.percent < 20 && 
                    <>
                        <img loading='lazy' data-animate='opacity' className='animate girl' style={{zIndex: "2"}} src={src} alt='photo1'/>
                        <img loading='lazy' data-animate='scale' className='animate ball1' style={{zIndex: "1"}} src={src2} alt='photo1'/>
                    </>
                }
                {
                    width < 700 ? 
                    <div data-animate='slideInLeft' style={{zIndex: "100"}} className='c_lg main-text animate'>
                        <h1>Electronic Dance <h1>Music Brand</h1></h1>
                        <p><i><b>Nexus</b></i> is a <i>premier brand</i><br/> specializing in the <i className='c_g'>planning,<br/> management, and execution</i> of <br/>electronic dance music events.</p>
                    </div> :
                    <div data-animate='slideInLeft' style={{zIndex: "100"}} className='c_lg main-text animate'>
                        <h1>Electronic Dance <h1>Music Brand</h1></h1>
                        <p><i><b>Nexus</b></i> is a <i>premier brand</i> specializing in the <i className='c_g'>planning, management, and execution</i> of electronic dance music events.</p>
                    </div>
                }
                    <div style={{zIndex: "100"}} className='c_lg text-description'>
                        <h2>Our approach combines innovative event  concepts with meticulous attention to detail, ensuring each event is uniquely memorable.</h2>
                    </div>
            </section>
            <section id='coordination' className='coordination-section'>
                {
                    props.percent >= 20 && props.percent < 40 &&
                    <>
                        <img loading='lazy' data-animate='opacity' className='animate girl' style={{zIndex: "2"}} src={src} alt='photo1'/>
                        <img loading='lazy' data-animate='scale' className='animate ball2' style={{zIndex: "1"}} src={src2} alt='photo1'/>
                    </>
                }
                <div data-animate='slideInRight' style={{zIndex: "100"}} className='animate c_lg main-text'>
                    <h1>Seamless Coordination</h1>
                    <p>We’re always providing exceptional experiences by <i className='c_blu'>hosting top-tier events,</i> ensuring <i className='c_blu'>seamless coordination between artists, agencies, and event organizers.</i></p>
                </div>
            </section>
            <section id='perfect' className='perfect-match-section'>
                {
                    props.percent >= 40 && props.percent < 60 &&
                    <>
                        <img loading='lazy' data-animate='opacity' className='animate girl' style={{zIndex: "2"}} src={src} alt='photo1'/>
                        <img loading='lazy' data-animate='scale' className='animate ball1' style={{zIndex: "1"}} src={src2} alt='photo1'/>
                    </>
                }
                <div data-animate='slideInLeft' style={{zIndex: "100"}} className='c_lg main-text animate'>
                    <h1>We think we’re the perfect match for you!</h1>
                    <p>With over <i className='c_g'>4-5 years of combined experience</i> in the industry, the founders have played <i className='c_g'>pivotal roles</i> in various capacities, including <i>investment and event organization.</i></p>
                </div>
                <div data-animate='slideInRight' style={{zIndex: "100"}} className='c_lg text-description animate'>
                    <p>Nexus is <i className='c_g'>dedicated to elevating the standard of events in the region,</i> focusing on <i>professionalism, reliability, and unmatched audience experiences.</i></p>
                </div>
            </section>
            <section id='services' className='services-section'>
                {
                    props.percent >= 60 && props.percent < 80 &&
                    <>
                        <p data-animate='slideInUp' className='c_lg animate hidden-text'>
                        {data[index]}
                        </p>
                        <div className='services'>
                            <Service
                            clsName="photo_0x01"
                            button="Artist Booking"
                            activeCards={props.activeCards}
                            handleCard={props.handleCard}
                            index={0}
                            />
                            <Service
                            clsName="photo_0x02"
                            button="Event Management"
                            activeCards={props.activeCards}
                            handleCard={props.handleCard}
                            index={1}
                            />
                            <Service
                            clsName="photo_0x03"
                            button="Venue Coordination"
                            activeCards={props.activeCards}
                            handleCard={props.handleCard}
                            index={2}
                            />
                            <Service
                            clsName="photo_0x04"
                            button="Hospitality & Logistics"
                            activeCards={props.activeCards}
                            handleCard={props.handleCard}
                            index={3}
                            />
                        </div>
                    </>
                }
            </section>
            <Contact
            percent={props.percent}
            />
        </main>
    );
}

export default Home;