import React from 'react';

function Service(props) {
    const clsName = `animate service photo ${props.clsName}`;
    const list = props.activeCards


    const styleCard = {
        borderRadius: !list[props.index] ? "1vw" : "100% 30% 100% 30%",
        transition: ".5s ease",
        border: !list[props.index] ? "none" : "1px solid #73BB3E"
    }
    const styleButton = {
        display: !list[props.index] ? "block" : "none"
    }
    return (
        <div data-animate="scale" style={styleCard} className={clsName}>
            <button style={styleButton} onClick={() => props.handleCard(props.index)}>{props.button}</button>
        </div>
    );
}

export default Service;