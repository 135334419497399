import { useState } from 'react';
import React from 'react';
import Footer from '../Footer';
import './Contact.css'
import { firestore } from './firebase';
import { collection, addDoc, updateDoc, doc, serverTimestamp } from 'firebase/firestore';


function Contact(props) {
    const [send, setSend] = useState(false);
    const [values, setValues] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        requestedService: "",
        companyName: "",
        otherService: ""
    })
    const addContactForm = async (formData) => {
    const { firstName, lastName, phoneNumber, email, companyName, requestedService, otherService } = formData;

    try {
        const docRef = await addDoc(collection(firestore, 'contactUsForm'), {
        firstName,
        lastName,
        companyName,
        phone: phoneNumber,
        email,
        service: requestedService === 'Other' ? otherService : requestedService,
        createdAt: serverTimestamp(),
        });

        // Add the document ID to the document
        await updateDoc(doc(firestore, 'contactUsForm', docRef.id), { id: docRef.id });

        console.log('Document successfully written with ID: ', docRef.id);
    } catch (error) {
        console.error('Error adding document: ', error);
    }
    };

    const handleSend = async (e) => {
        e.preventDefault();
        await addContactForm(values); // Pass the form values to the addContactForm function
        setSend(true);
        setTimeout(() => {
            setSend(false);
        }, 4000);
    };

    const onChange = (e) => {
        setValues({...values, [e.target.name]: e.target.value});
    }

    console.log(values)
    return (
        <section id='contact' className='contact-section c_lg'>
            {
                props.percent >= 80 && props.percent < 101 &&
                <>
                    <img data-animate='slideInRight' src='./images/hand.svg' className='hand1 animate' alt='hand'/>
                    <img data-animate="slideInRight" src='./images/hand.svg' className='hand2 animate' alt='hand'/>
                    {
                        !send ?
                        <div data-animate="scale" className='form-container animate'>
                            <p>Leave your contact details & we’ll get back to you ASAP!</p>
                            <form onSubmit={handleSend}>
                                <input
                                type='text'
                                name="firstName"
                                onChange={onChange}
                                placeholder='First Name'
                                required
                                />
                                <input
                                type='text'
                                name="lastName"
                                onChange={onChange}
                                placeholder='Last Name'
                                required
                                />
                                <input
                                type='email'
                                name="email"
                                onChange={onChange}
                                placeholder='E-mail'
                                required
                                />
                                <input
                                type='text'
                                name="phoneNumber"
                                onChange={onChange}
                                placeholder='Phone Number'
                                required
                                />
                                <input
                                type='text'
                                name="companyName"
                                onChange={onChange}
                                placeholder='Company Name (Optional)'
                                className='company-name'
                                />
                                <select
                                name="requestedService"
                                onChange={onChange}
                                required
                                >
                                    <option value="">Select a Service</option>
                                    <option value="Artist Booking">Artist Booking</option>
                                    <option value="Event Management">Event Management</option>
                                    <option value="Venue Coordination">Venue Coordination</option>
                                    <option value="Hospitality & Logistics">Hospitality & Logistics</option>
                                    <option value="Other">Other</option>
                                </select>
                                {values["requestedService"] === "Other" && (
                                    <input
                                        type="text"
                                        placeholder="Enter other service"
                                        name="otherService"
                                        onChange={onChange}
                                        required
                                    />
                                )}
                                <button>Send</button>
                            </form>
                        </div> :
                        <div data-animate="scale" className='form-container animate'>
                            <p><b>We got your request! We’ll get back to you ASAP</b></p>
                        </div>

                    }
                    <Footer/>
                </>
            }
        </section>
    );
}

export default Contact;