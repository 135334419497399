import React from 'react';
import './Header.css'
import { useState, useEffect } from 'react';

function Header(props) {
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        // Function to update the width state
        const handleResize = () => setWidth(window.innerWidth);

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Clean up event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleNav = () => {
        setTimeout(() => {
            window.scrollBy(0, 1);
            window.scrollBy(0, -1);
        }, 100);
    }

    const list = props.activeCards;
    let hi;
    let color;

    if (props.percent >= -100 && props.percent < 20) {
        hi = './images/hiGreen.svg';
        color = 'c_g'
    } else if (props.percent >= 20 && props.percent < 40) {
        hi = './images/hiBlue.svg';
        color = 'c_blu'
    } else if (props.percent >= 40 && props.percent < 60) {
        hi = './images/hiGreen.svg';
        color = 'c_g'
    } else if (props.percent >= 60 && props.percent < 200) {
        color = 'c_lg'
    }

    if (width > 900) {
        return (
            <header data-animate='slideInLeft' style={{zIndex: "1000"}} className='animate header-big'>
                <a onClick={handleNav} href='#main' className='logo'>
                    <img src='./images/logo.svg' alt='logo'/>
                </a>
                <div className='header-nav'>
                    <a onClick={handleNav} href='#main' className='nav-item'>
                        {props.percent < 60 && <img className='hi' src={hi} alt='hi'/>}
                        <h3 className={color}>Hi!</h3>
                    </a>
                    <a onClick={handleNav} href='#services' className='big-nav'>
                        {props.percent >= 60 && props.percent < 90 && 
                        <img className='services-image' src='./images/services.svg' alt='serices'/>}
                        <h4 className={props.percent >= 60 && props.percent < 90 ? "c_g" : "c_lg"}>Our Services</h4>
                    </a>
                    {
                        list[0] === false ?
                        <a href='#services' onClick={() => {props.handleCard(0); handleNav()}} className='c_lg small-nav'>Artist Booking</a> :
                        <a onClick={handleNav} href='#services' className='nav-item'>
                            {
                                props.percent < 90 && props.percent > 60 ?
                                <>
                                    <img src='./images/service.svg' alt='serivce'/>
                                    <p onClick={() => props.handleCard(0)} className='c_blu small-nav'>Artist Booking</p>
                                </> :
                                <p onClick={() => props.handleCard(0)} className='c_lg small-nav' style={{marginTop: "8px"}}>Artist Booking</p>
                            }
                        </a>
                    }
                    {
                        list[1] === false ?
                        <a href='#services' onClick={() => {props.handleCard(1); handleNav()}} className='c_lg small-nav'>Event Management</a> :
                        <a onClick={handleNav} href='#services' className='nav-item'>
                            {
                                props.percent < 90 && props.percent > 60 ?
                                <>
                                    <img src='./images/event.svg' alt='service'/>
                                    <p onClick={() => props.handleCard(1)} className='c_blu small-nav'>Event Management</p>
                                </> :
                                    <p onClick={() => props.handleCard(1)} className='c_lg small-nav' style={{marginTop: "8px"}}>Event Management:</p>
                            }
                        </a>
                    }
                    {
                        list[2] === false ?
                        <a href='#services' onClick={() => {props.handleCard(2); handleNav()}} className='c_lg small-nav'>Venue Coordination</a> :
                        <a onClick={handleNav} href='#services' className='nav-item'>
                            {
                                props.percent < 90 && props.percent > 60 ?
                                <>
                                    <img src='./images/venue.svg' alt='service'/>
                                    <p onClick={() => props.handleCard(2)} className='c_blu small-nav'>Venue Coordination</p>
                                </> :
                                    <p onClick={() => props.handleCard(2)} className='c_lg small-nav' style={{marginTop: "8px"}}>Venue Coordination</p>
                            }
                        </a>
                    }
                    {
                        list[3] === false ?
                        <a href='#services' onClick={() => {props.handleCard(3); handleNav()}} className='c_lg small-nav'>Hospitality & Logistics</a> :
                        <a onClick={handleNav} href="#services" className='nav-item'>
                            {
                                props.percent < 90 && props.percent > 60 ?
                                <>
                                    <img src='./images/hospitality.svg' alt='service'/>
                                    <p onClick={() => {props.handleCard(3)}} className='c_blu small-nav'>Hospitality & Logistics</p>
                                </> :
                                    <p onClick={() => {props.handleCard(3)}} className='c_lg small-nav'>Hospitality & Logistics</p>
                            }
                        </a>
                    }
                    <a onClick={handleNav} href='#contact' className='contact-us-nav'>
                        {props.percent >= 90 && props.percent < 110 && 
                        <img className='services-image' src='./images/contact.svg' alt='service'/>}
                        <h4 className={props.percent >= 90 && props.percent < 110 ? "c_g" : "c_lg"}>Contact Us</h4>
                    </a>
                </div>
            </header>
        );
    } else {
        return (
            <header data-animate='slideInDown' style={{zIndex: "1000"}} className='animate header-small'>
                <a onClick={handleNav} href='#main' className='logo-small'>
                    <img src='./images/logo.svg' alt='logo'/>
                </a>
                <div className='header-nav-small'>
                    <a onClick={handleNav} href='#main' className='nav-item-small'>
                        {props.percent < 60 && <img className='hi' src={hi} alt='hi'/>}
                        <h4 className={color}>Hi!</h4>
                    </a>
                    <a onClick={handleNav} href='#services' className='nav-item-small'>
                        <h4 className={props.percent >= 60 && props.percent < 90 ? "c_g" : "c_lg"}>Our Services</h4>
                    </a>
                    <a onClick={handleNav} href='#contact' className='nav-item-small'>
                        <h4 className={props.percent >= 90 && props.percent < 110 ? "c_g" : "c_lg"}>Contact Us</h4>
                    </a>
                </div>
            </header>
        )
    }
}

export default Header;