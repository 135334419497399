import './App.css';
import { Route, Routes, BrowserRouter } from 'react-router-dom'
import Header from './Header';
import Home from './pages/Home';
import Welcome from './Welcome';
import './Animate.css'
import { useState, useEffect } from 'react';

function App() {
    const [scrollPercentage, setScrollPercentage] = useState(0);
    const [activeCards, setActiveCards] = useState([true, false, false, false])

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
            setTimeout(() => {
                window.scrollBy(0, 1);
                window.scrollBy(0, -1);
            }, 100);
        }, 100);
    }, [])
    useEffect(() => {
        const handleScroll = () => {
            var elem = document.querySelectorAll('.animate');
            var scrollPos = window.scrollY;
            var wh = window.innerHeight;

            elem.forEach(function (el) {
                var isAnimate = el.dataset.animate;
                var elemOffset = el.offsetTop;

                if (scrollPos > elemOffset - wh + (wh / 4)) {
                    el.classList.add(isAnimate);
                }
            });
        };

        handleScroll();

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleCard = (index) => {
        const newActiveCards = activeCards.map((card, idx) => idx === index);
        setActiveCards(newActiveCards);
    };


    const handleScroll = () => {
        const scrollY = window.scrollY;
        const scrollableHeight = document.documentElement.scrollHeight - window.innerHeight;
        const percentage = (scrollY / scrollableHeight) * 100;

        setScrollPercentage(percentage);
    };

    useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    // Cleanup event listener on component unmount
    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
    }, []);

    return (
    <div className="App">
        <BrowserRouter>
            <Welcome/>
            <Header percent={scrollPercentage} handleCard={handleCard} activeCards={activeCards}/>
            <Routes>
                <Route path="/" element={<Home percent={scrollPercentage} handleCard={handleCard} activeCards={activeCards}/>}/>
            </Routes>
        </BrowserRouter>
    </div>
    ); 
}

export default App;
